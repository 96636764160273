.dashs__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}


.dash {
    background: hsl(174, 62%, 67%);
    padding: 1rem;
    border-radius: var(--radius-3);
    display: flex;
    gap: 1rem;
    transition: var(--transition);
    display: flex;
    justify-content: center;
}

.dashs {
  padding: 2rem;
  min-height: 47vh;
}

.dash:hover {
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.1);
}

.dash__avatar {
    width: 5rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 0.3rem solid var(--color-bg);
    overflow: hidden;
}

/* .dash__info h4 {
    text-align: center;
} */

.dash__info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* This ensures the parent div takes up the full height */
  }
  
  .icon-container-dash {
    display: inline-block;
    padding: 10px 0px 5px 10px; /* Top, Right, Bottom, Left */
    font-size: 24px; /* Adjust the font size as needed */
    color: #ebe526; /* Initial color */
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  .icon-container-dash:hover {
    color: #636363; /* Hover color */
    transform: scale(1.2); /* Scale up on hover */
    animation: pulse 0.5s infinite; /* Apply a pulse animation */
  }
    

  /* main dashbaord  */
  .app {
    display: flex;
  }
  
  .sidebar {
    width: 200px;
    background-color: #f0f0f0;
    transition: width 0.3s ease;
  }
  
  .sidebar.closed {
    width: 60px;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .sidebar button {
    margin: 10px;
  }
  

.dashboard {
  /* padding-top: -2%; */
}  

.dashSearchbtn {
  padding: 2%;
  display: 'flex';
  justifyContent: 'center';
}
/* for sidebar */

.thumbnail {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Automatically adjust the height */
  margin-right: 10px; /* Optional: Add margin between thumbnails */
}

.thumbnail-container {
  display: flex; /* Display thumbnails in a row */
}

.submitbtn {
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    width: 200px; /* Adjust width as needed */
    display: inline-block;
    background-color: hsl(166, 83%, 37%) !important;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 4%; 
}

.submitbtn2 {
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  width: 200px; /* Adjust width as needed */
  display: inline-block;
  background-color: hsl(166, 83%, 37%) !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitbtn:hover {
  background-color: #5f615f !important;
}

.submitbtn2:hover {
  background-color: #5f615f !important;
}

.boxdash{
  text-align: center;
}

.thumbnaildash {
  display: flex;
  align-items: center;
}

.thumbnaildash span {
  font-size: 11px; /* Adjust font size as needed */
  margin-right: 2px; /* Add some space between span and input */
  width: 120px;
}
.thumbnaildash input[type="file"] {
  /* Adjust input styling as needed */
}


.thumbnaildash2 {
  display: flex;
  align-items: center;
}

.thumbnaildash2 span {
  font-size: 11px; /* Adjust font size as needed */
  margin-right: 2px; /* Add some space between span and input */
  width: 150px;
}
.thumbnaildash2 input[type="file"] {
  /* Adjust input styling as needed */
}

.custom-file-input {
  width: 220px; /* Adjust the width as needed */
  /* Add any other custom styles you want */
}

.expandable-textarea {
  width: 100%; /* Adjust width as needed */
  min-height: 100px; /* Minimum height */
  max-height: 300px; /* Maximum height */
  padding: 8px; /* Adjust padding as needed */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto; /* Add vertical scrollbar when needed */
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
}

.thumbnail-wrapper {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.thumbnail {
  width: 100px; /* Adjust thumbnail size as needed */
  height: 100px; /* Adjust thumbnail size as needed */
  object-fit: cover; /* Ensure the image covers the entire area */
  border: 1px solid #b6adad;
  border-radius: 5px;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(219, 211, 211, 0.8);
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-button:hover {
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
}

/* for contacts dashboard */
.category-container {
  display: flex;
  align-items: center; /* optional: align items vertically */
  margin-bottom: 2%;
}

.category-container input {
  margin-right: 12px; /* optional: add margin between input and button */
}

.half-width-input {
  width: 50%; /* Set width to 50% of the container */
}

.add-button {
  background-color: hsl(166, 83%, 37%); /* Example color */
  color: white;
  border: none;
  border-radius: 50%; /* Rounded border */
  padding: 5px 8px; /* Adjust padding as needed */
  cursor: pointer;
}

.add-button:hover {
  background-color: hsl(166, 81%, 27%); /* Darker shade of the primary color */
}

/* dashboard contacts */
.dash-contacts {
  font-size: xx-small;
}

.dash-cat {
  color: #1fa194;
  margin-top: 16px;
}

/* for complaints dashboard */
/* Existing styles */

.popover-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popover {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.popover-image {
  
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.popover-actions {
  margin-top: 10px;
}

.popover-actions button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .dashs__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
}
}  


/* contact seller css */
.container-seller {
  display: flex;
  align-items: center;
}

.minus-button {
  display: inline-flex;
  align-items: center;
  padding: 1px 8px;
  margin-right: 0px;
  background-color: #ff1100;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.add-button {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  margin-right: 10px;
  background-color: #1ad833;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  cursor: pointer;
}

.add-button .cross-icon {
  margin-right: 5px;
  font-size: 20px;
}

.minus-button .cross-icon {
  /* margin-right: 5px; */
  font-size: 20px;
}

