@import url('../index.css');

.home-page {
    filter: brightness(120%);
  }

.home-page {
position: relative;
width: 100%;
height: 600px; /* Adjust based on your needs */
overflow: hidden;
}

.home-page img {
width: 100%;
height: 100%;
object-fit: cover;
}

.overlay-container {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
/* color: white;  */
}

/* banner.css */
.white-bar {
    background-color: rgb(255, 255, 255);
    padding: 26px;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
    margin: 50px 20px -220px 20px;
    border: #000;
    border-width: 2px;
  }
  
  .grid-bar {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 30px;
    align-items: center;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    /* padding-right: 30px; */
  }
  
  .grid-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 30px;
    /* background-color: #ccc; */
  }
  
  .grid-item a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
    transition: transform 0.3s ease;
  }
  
  .grid-item a:hover {
    transform: translateY(-5px);
  }
  
  .grid-item img {
    max-width: 50px;
    margin-bottom: 10px;
  }
  
  .grid-item span {
    font-weight: bold;
  }
   
.home-page h1 {
    color: var(--color-black);
    padding: 5px;
    margin-top: -60px;
}  

.home-page h2 {
    color: var(--color-black);
} 

.home-page p {
    color: hsl(0, 1%, 83%);
    margin: 20px 0px 0px 0px;
} 

.home-page a {
    color: var(--color-black);
}

.vertical-bar {
    width: 2px;
  }

/* .grid-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 30px;
    background-color: #3abf01;
  }   */

/* icon animation   */
.icon-container {
    display: inline-block;
    padding: 10px 10px 5px 10px; /* Top, Right, Bottom, Left */
    font-size: 30px; /* Adjust the font size as needed */
    color: #1b8e83; /* Initial color */
    transition: all 0.3s ease; /* Smooth transition */
    display: inline-block;
   
    transition: transform 0.2s ease-in-out; /* Add a smooth transition effect */
  }
  
  .icon-container:hover {
    color: #d2b10e; /* Hover color */
    transform: scale(1.4); /* Scale up on hover */
    animation: pulse 0.5s infinite; /* Apply a pulse animation */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
/* text block */
.two-division-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
  }
  
  .left-division {
    display: flex;
    align-items: center;
    flex: 1;
  }
  
  .icon-text {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
  
  .icon-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .vertical-bar {
    width: 4px;
    height: 100px;
    background-color: #1fa194;
    margin-right: 20px;
  }
  
  .right-division {
    flex: 3;
    text-align: center;
  }
  
  .content-wrapper {
    display: inline-block;
    text-align: left;
  }

  
  
  .large-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .side-img {
    max-width: 200px;
    margin-top: 10px;
  }

  /* grid images  */
  .gridimage {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .gridimage-item {
    position: relative;
    overflow: hidden;
  }
  
  .gridimage-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .overlayimage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overlayimage-text {
    color: #fff;
    font-size: 24px;
  }
  
  .gridimage-item:hover img {
    transform: scale(1.1);
  }
  
  .gridimage-item:hover .overlay {
    opacity: 1;
  }


  /* for cards  */
 
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    width: 312px; /* Adjust card width as needed */
    height: 300px; /* Adjust card height as needed */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    flex: 0 0 auto;
    margin-right: 20px; 
  }
  
  .card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px) rotate(5deg); }
    50% { transform: translateX(0); }
    75% { transform: translateX(5px) rotate(-5deg); }
    100% { transform: translateX(0); }
  }
  
  .card-img {
    border-radius: 10px;
  }
  
  .card-content {
    padding: 4px;
    margin-top: 2%;
    /* padding-top: 10px; */
    align-items: center;
    text-align: center;
  }
  
  .card-name {
    margin: 0;
  }
  
  .card-location {
    display: flex;
    align-items: center;
    color: #777;
    margin-bottom: 10px;
    font-size: smaller;
  }
  
  .card-location svg {
    margin-right: 5px;
  }
  
 
  .card-details-btn {
    width: 8cqmax; /* Make button full-width */
    padding: 5px 0; /* Adjust padding as needed */
    background-color: var(--main-color);
    color: #fff;
    border: none;
    border-radius: 20px; /* Make button rounded oval */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .card-details-btn:hover {
    background-color: var(--color-black);
  }
  
  

  /* scroll */
/* MainComponent.css */
.main-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 2%;
    /* background-color: #dfe7fa; */
  }

  .maincompHeading {
    padding: 1%; 
    text-align: center; 
    color: #1b8e83;
  }
  
  .cards-main-container {
    display: flex;
    gap: 10px;
    padding: 10px; /* Adjust padding as needed */
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .cards-main-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
  }

  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 30px; /* Increase font size for arrows */
    color: #ffffff; /* Default color for arrows */
    transition: color 0.3s ease; /* Smooth transition for color change */
  }
  
  .prev:hover,
  .next:hover {
    color: var(--main-color); /* Change color on hover */
  }
  
  .prev:active,
  .next:active {
    color: #000; /* Change color on click */
  }
  
  .prev {
    left: 10px; /* Adjust left position as needed */
  }
  
  .next {
    right: 10px; /* Adjust right position as needed */
  }
  

  /* mid banner  */
.carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.images {
  display: flex;
  transition: transform 0.5s ease;
}

.images img {
  width: 100%;
  height: auto;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 18px;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.active {
  display: block;
}

/* crousel */
.carousel-container {
    margin: 0 auto;
    padding: 20px;
  }
  
  .carousel-image {
    position: relative;
    margin-right: 20px;
  }

  .carousel-image img {
    width: 450px; /* Set a fixed width for all images */
    height: 300px; /* Set a fixed height for all images */
    object-fit: cover; /* Ensure the entire image is visible, regardless of its aspect ratio */
    border-radius: 10px; /* Optional: Add border-radius to images */
  }
  
  .card-crousel {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.458);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    width: 80%; /* Set the width of the card-crousel */
    max-width: 240px; /* Set the maximum width of the card-crousel */
  }
  
  .card-crousel h3 {
    margin: 0 0 5px;
    color: #ffffff;
    text-shadow: #000;
  }
  
  .card-crousel p {
    margin: 0;
  }
  
  .card-crousel button {
    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 1%;
    max-height: 150px;
    width: 80%;
  }
  
  
  .crousel-container {
    margin: 0 auto;
    padding: 20px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    padding-bottom: 4%;
    margin: 0 auto;
    /* background-color: #eefdfa */
  }

 
  
  .crousel-container h2 {
    color: #000;
    padding-top: 4%;
    margin: 0 auto; 
    width: 80%; /* Adjust the width of h2 */
    margin-bottom: 2%;
    text-align: center;
  }
  
  .crousel-container p {
    width: 70%;
    margin: 0 auto; 
    padding-bottom: 2%;
    text-align: center;
  }
  

  /* second banner */
  .container-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #baf2f1;
    padding: 2%;
    margin: 4%;
  }
  
  .row-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  
  .column-banner {
    flex: 1;
    padding: 20px;
    /* text-align: center; */
    display: flex;
  flex-direction: column;
  /* align-items: center; */
  }
  
  .column-banner img {
    width: 40%; /* Ensures the image fills the column */
  height: 200px; /* Set the desired height of the image */
  object-fit: cover; /* Ensures the image covers the entire space */
  
  }
  
  .btn {
    padding: 10px 20px;
    margin: 10px;
    background-color: #36dae6;
    border: none;
    cursor: pointer;
    width: 25%;
  }
  
  .btn:hover {
    background-color: #888;
  }
  
  .column-banner h2 {
    margin: 2%;
  }

  .column-banner p {
    margin: 2%;
  }

  /* youtube video */
.youtube-video {
    padding: 4%;
}

/* home page css  */
.banner_heading {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #dcff15 !important;
  text-shadow: 2px 2px 4px rgba(8, 8, 8, 0.8);
  animation: pulsate 2s ease-in-out infinite;
}

@keyframes pulsate {
  0% {
      transform: scale(1); /* Initial scale */
  }
  50% {
      transform: scale(1.1); /* Scale up to 110% */
  }
  100% {
      transform: scale(1); /* Back to original scale */
  }
}
.banner_subheading {
  color: #ffffff !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  animation: pulsate 2s ease-in-out infinite;
}

.banner_minheading {
  text-shadow: 2px 2px 4px rgba(12, 12, 12, 0.8);
  font-weight: 700;
  color: #ffffff !important;
  padding-top: 20px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.bannerHospitality {
  color: #000;
  margin: 0 auto; 
  width: 80%; /* Adjust the width of h2 */
  margin-bottom: 4%;
  text-align: center;
}


.submitbtnHome {
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  width: 200px; /* Adjust width as needed */
  display: inline-block;
  background-color: hsl(166, 83%, 37%) !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* margin: 4%;  */
}


.submitbtnHome:hover {
background-color: #5f615f !important;
}

/* MEDIA QUERY */
.white-bar-mobile {
  display: none; /* Initially hide for larger screens */
}

@media only screen and (max-width: 800px) {
  /* for white bar */
  .white-bar {
    display: none; /* Hide the original white bar for mobile */
  }

  .white-bar-mobile {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    /* box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); */
    padding: 10px 0;
    z-index: 999; /* Ensure it's above other content */
    display: block; /* Show for smaller screens */
    padding: 1%;
    border: 1.5px solid #f2f0f0 !important;
    /* margin: 2rem; */
    /* border-radius: 20%; */
  }

  .grid-bar {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 2%; */
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #555;
    font-size: 14px;
    text-decoration: none;
  }

  /* .icon-container {
    font-size: 30px;
  } */

  /* .icon-container svg {
    margin-bottom: 5px;
  } */

  .grid-item span {
    font-size: x-small;
    color: #3d3c3c;
  }

  .icon-container {
    display: inline-block;
    font-size: 1.6rem; /* Adjust the font size as needed */
    color: #1b8e83 !important; /* Initial color */
    transition: all 0.3s ease; /* Smooth transition */
    display: inline-block;
    transition: transform 0.2s ease-in-out; /* Add a smooth transition effect */
  }
  
  .icon-container:hover {
    color: #d2b10e !important; /* Hover color */
    transform: scale(1.4); /* Scale up on hover */
    animation: pulse 0.5s; /* Apply a pulse animation */
  }
  
  /* for home page image */
  .home-page {
    height: 20rem; /* Adjust based on your needs */
    }

  .banner_heading {
    font-size: 1.3rem;
    /* margin-bottom: 1rem; */
  }

  .overlay-container {
    margin-top: 1.6rem;
  }


  .banner_subheading {
    font-size: 1.2rem;
    /* padding: 0rem 0rem 0rem 2rem; */
    text-align: center;
    padding: 0.8rem 2rem 0rem 2rem;
  }

  .banner_minheading {
    font-size: 0.8rem;
    /* margin-top: 8rem; */
    line-height: 1.4;
    text-align: center;
    padding: 2%;
    margin-bottom: -6rem;
    padding-top: 4rem;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #555;
    font-size: 14px;
    text-decoration: none;
  }
  
  .icon-container {
    font-size: 1.6rem;
  }

  .icon-text h2 {
    font-size: 1rem;
  }

  .content-wrapper h4 {
    font-size: 1rem;
  }

  .content-wrapper p {
    font-size: 0.8rem;
  }

  .icon-text {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  .two-division-block {
    display: flex;
    padding: 14px;
    margin-top: 8px;
  }

  .maincompHeading {
    font-size: 1.2rem;
    padding: 1.4rem;
    text-align: center;
  }

  .cards-main-container {
    display: flex;
    gap: 10px;
    padding: 2px; /* Adjust padding as needed */
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

   
  .card {
    width: 22rem; /* Adjust card width as needed */
    height: 20rem; /* Adjust card height as needed */
    margin: 0rem 0rem 0rem 1rem; 
  }

  .cards-main-container {
    gap: 2rem;
    padding: 10px; /* Adjust padding as needed */
  }
  
  .crousel-container h2 {
    font-size: 1.1rem;
    text-align: center;
  }

  .crousel-container p {
    font-size: 0.8rem;
    text-align: center;
    width: 85%;
    padding: 0.5rem;
  }

  .carousel-image {
    position: relative;
    margin-right: 20px;
  }

  .carousel-image img {
    width: 21rem; /* Set a fixed width for all images */
    height: 300px; /* Set a fixed height for all images */
    object-fit: cover; /* Ensure the entire image is visible, regardless of its aspect ratio */
    border-radius: 10px; /* Optional: Add border-radius to images */
  }

  
  .row-banner {
    flex-direction: column; /* Change to column layout on small screens */
    justify-content: center;
  }
  
  .column-banner img {
    width: 70%; /* Ensure image takes full width of column */
    height: auto; /* Let the height adjust proportionally */
    max-width: 100%; /* Ensure image doesn't exceed column width */
  }
  
  .btn {
    width: 100%; /* Ensure button takes full width */
  }
  
  .btn:hover {
    background-color: #888;
  }
  
  .column-banner h2,
  .column-banner p {
    text-align: center; /* Center text on small screens */
  }
  .hideImage {
    display: none;
  }

  .youtube-video {
    margin: 4%;
    padding-top: 3rem;
}

.youtube-video iframe {
  height: 100%;
}
.column-banner {
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.bannerHospitality {
  font-size: 1.2rem;
  padding-bottom: 3rem;
}

.right-division {
  flex: 2;
  text-align: center;
}
  
}

.horizontal-bar-style {
  width: 100%;
  height: 2px;
  background-color: #f0f0f0; /* Set the background color of the outer bar */
  border-radius: 10px; /* Rounded corners for the outer bar */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center the inner bar horizontally */
  overflow: hidden; /* Hide overflow from the inner bar */
}

.inner-bar {
  height: 100%;
  width: 60%; /* Adjust the width of the inner bar as needed */
  background: linear-gradient(to right, #ebe011 0% , #36dae6 100%); /* Stylish gradient effect */
  border-radius: 10px; /* Rounded corners for the inner bar */
}



