.form-graph {
  display: flex;
  flex-direction: column;
  max-height: 82vh;
  overflow-y: auto;
}


/* Hiding the scrollbar */
.form-graph::-webkit-scrollbar {
  width: 0; /* Hides the scrollbar */
}


.form-graph {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.form-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
 .form-row select,
.form-row input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}


.form-graph select,
.form-graph input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}
 .form-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
 .icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
 .icon-button:hover {
  background-color: #0056b3;
}


.icon-buttondel {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
 .icon-buttondel:hover {
  background-color: #ca0404;
}


.icon-buttonSeries {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4c807;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
 .icon-buttonSeries:hover {
  background-color: #d6b006
}
 .submit-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
 .submit-button:hover {
  background-color: #218838;
}
 @media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
.formCategory {
  margin-bottom: 0.8rem;
}





