.create-post {
    padding: 20px;
  }
  
  .container {
    /* max-width: 600px; */
    margin: 0 auto;
  }
  
  .form__error-message {
    color: red;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .form.create-post__form input,
  .form.create-post__form textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  .dynamic-field-list {
    margin-bottom: 20px;
  }
  
  .item-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .item-container input {
    flex: 1;
    margin-right: 10px;
  }
  
  .btn {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .add-btn {
    background-color: #28a745;
    color: white;
  }
  
  .remove-btn {
    background-color: #dc3545;
    color: white;
    padding: 4px 8px; /* Reduce padding to make it smaller */
    font-size: 12px;  /* Reduce font size */
    border-radius: 3px; /* Optional: adjust the border radius */
    height: 30px; /* Optional: set a specific height */
    width: 30px;  /* Optional: set a specific width */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .thumbnaildash2 {
    margin-bottom: 20px;
  }
  
  .submitbtn2 {
    background-color: #007bff;
    color: white;
    width: 100%;
  }
  
  .submitbtn2:hover,
  .add-btn:hover,
  .remove-btn:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .form.create-post__form input,
    .form.create-post__form textarea {
      font-size: 14px;
    }
  
    .btn {
      font-size: 12px;
    }
  }

  /*  for display notes Notes.jsx */

  