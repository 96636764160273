.dashboardProfile .containerProfile {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }
  
  .add-graph-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .add-graph-button:hover {
    background-color: #0056b3;
  }
  
  .profile-section {
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: #f9f9f900;
  }
  
  .accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    width: 15%;
    text-align: left;
  }
  
  .accordion:hover {
    background-color: #0056b3;
  }
  
  .profile-content {
    margin-top: 1rem;
  }
  
  .graph-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    background-color: #ffffff;
    margin: 1rem;
    padding: 2rem;
  }
  
  .graph-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .icon-button:hover {
    background-color: #0056b3;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 3rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    z-index: 1000;
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); /* Adding blur effect */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; /* Ensure overlay is just below the modal */
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .dash-item {
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
}

.dash-item2 {
  /* border: 1px solid #ddd; */
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
}

  
  @media (max-width: 768px) {
    .graph-item {
      flex-direction: column;
      align-items: flex-start;
    }
    .icon-button {
      margin-top: 0.5rem;
    }

    .dash-item2 {
      /* border: 1px solid #ddd; */
      padding: 0rem;
      text-align: center;
    }

    .dash-item {
      /* border: 1px solid #ddd; */
      padding: 0rem;
      text-align: center;
    }
  }
  
