@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

/* general style */
:root{
    --color-primary: #e9cf27;
    --color-white: #fff;
    --color-red: rgb(255,63,63);
    --color-gray-100: hsl(220, 20%, 10%, 5%);
    --color-gray-200: hsl(220, 20%, 10%, 10%);
    --color-gray-300: hsl(220, 20%, 10%, 25%);
    --color-gray-500: hsla(60, 2%, 25%, 0.5);
    --color-gray-700: #6c6b68;
    --color-gray-900: #3c3e3c;
    --color-black: hsl(0, 0%, 0%);
    --main-color: #1fa194;

    --color-bg: hwb(51 97% 0%);

    --transition: all 300ms ease;

    --container-width-lg: 84%;
    --container-width-md: 92%;
    --form-width: 50%;
    --post-width: 78%;

    --radius-1: 0.3rem;
    --radius-2: 0.6rem;
    --radius-3: 1rem;
    --radius-4: 2rem;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
}

.dashboard * {
    box-sizing: content-box; /* or whatever box-sizing you want for this page */
  }

body {
    font-family: "Poppins", sans-serif;
    background: #e5fbf9;
    font-size: 0.9rem;
    line-height: 1.6;
    color: var(--color-gray-700);
}

a {
    color: var(--color-white);
    text-decoration: none;
}

nav a {
    color: var(--color-black);
  }

ul {
    list-style: none;
}

.imageClass{
    display: block;
    object-fit: cover;
    width: 100%;
}

.imageClassLogo{
    display: block;
    object-fit: cover;
    width: 100%;
}

.imageClassHome{
    display: block;
    object-fit: cover;
    width: 100%;
    height: 11rem;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--color-gray-900);
    line-height: 1.4;
    /* this is best font usw it over everywhere */
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 1.9rem;
}

h3 {
    font-size: 1.3rem;
    line-height: 1.25;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    line-height: 1.4;
}

section {
    margin-top: 6rem;
}

.container {
    width: var(--container-width-lg);
    margin-inline: auto;
}

.btn {
    display: inline-block;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: var(--radius-2);
    background: var(--color-white);
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
}

.btn.primary {
    background: var(--color-primary);
    color: var(--color-white);
}

.btn.category {
    background: var(--color-bg);
    color: var(--color-primary);
    font-size: 0.75rem;
    font-weight: 400;
}

.btn.sm {
    font-size: 0.8rem;
    padding: 0.3rem 0.7rem;
    font-weight: 400;
}

.btn.danger {
    background: var(--color-red);
    color: var(--color-white);
}

.btn:hover {
    background: var(--color-gray-900);
    color: var(--color-white);
}

.center {
    text-align: center;
    padding-top: 5rem;
    margin-inline: center;
    display: block;
    width: 100%;
}

/* error page */

.error-page h2 {
    margin-top: 3rem;
}

/* navbar */

nav {
    width: 100vw;
    height:4rem;
    display: grid;
    place-items: center;
    /* background: var(255, 255, 255, 2); */
    /* background-color: rgba(0, 0, 0, 0.2);  */
    position: fixed;
    color: var(--color-black);
    top: 0;
    left: 0;
    z-index: 1;
    /* border-bottom: 1px solid var(--color-primary); */
    /* backdrop-filter: blur(20px); */
    transition: background-color 0.3s ease; /* Smooth transition */
}

nav.scrolled {
    background-color: #ffffff; /* Background color when scrolled */
  }

.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.nav__toggle-btn {
    display: none;
}

.nav__menu {
    display: flex;
    align-items: center;
    gap: 0.1rem;
}

.nav__menu li:hover a {
    color: #ffffff !important;
}


.nav__logo{
    width: 12rem;
    display: block;
}

/* footer */
footer {
    background: #47cfc1;
    margin-top: 6rem;
    padding-top: 6rem;
}

.footer__categories {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 4rem;
}

.footer__categories a {
    background: #1e7c73;
    color: var(--color-white);
    padding: 0.6rem 1.4rem;
    border-radius: var(--radius-2);
    transition: var(--transition);
}

.footer__categories a:hover {
    background: var(--color-white);
    color: var(--color-gray-900);
}

.footer__copyright {
    text-align: center;
    border-top: 2px solid var(--color-gray-700);
    padding: 1rem;
    color: var(--color-white);
    font-weight: 300;
}

/* Home */

/* posts */ 
.posts__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}



.post {
    background: var(--color-white);
    padding: 1rem 1rem 0rem 1rem;
    border-radius: var(--radius-4);
    /* padding-bottom: 2rem; */
    transition: var(--transition);
    cursor: default;
} 

.post-contacts {
    background: var(--color-white);
    padding: 1rem 1rem 0rem 1rem;
    border-radius: var(1rem);
    padding-bottom: 2rem;
    transition: var(--transition);
    cursor: default;
    padding-bottom: 1rem;
    border-radius: var(2rem);
}

.post:hover {
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}

.post-contacts:hover {
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}

.post_content h4 {
    margin-bottom: 2%;
    margin-top: 3%;
    color: #0a9172;
    font-size: medium;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.post__thumbnail {
    border-radius: var(--radius-3);
    overflow: hidden;
    height: 11rem;
}

.post__content {
    margin-top: 1.5rem;
}

.post__content {
    margin: 1rem 0 0.6rem;
}

.post__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2rem;
}

.post__author {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.post__author-avatar {
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--radius-2);
    overflow: hidden;
}

/* post detail */
.post-detail {
    width: var(--post-width);
    background: var(--color-white);
    margin-inline: auto;
    padding: 2rem 0;
}

.post-detail__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.post-detail__buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.post-detail__thumbnail {
   margin: 1rem 0;
   height: fit-content;
   max-height: 30rem;
   overflow: hidden; 
}

.post_content h5 {
    color: #5b6362;
    font-size:smaller;
    line-height: 1.7;
    word-spacing: 0.1rem;
}
/* .post-detail p {
    margin-bottom: 0.7rem;
} */

/* register and form */
.form {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
} 

input, select, textarea {
    width: 100%;
    padding: 0.6rem 1rem;
    border-radius: var(--radius-1);
    resize: none;
}

.form__error-message {
    background: var(--color-red);
    color: var(--color-white) !important;
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
    border-radius: var(--radius-1);
    display: block;
    margin-top: 1rem;
}

.register, .login {
    width: var(--form-width);
    margin-inline: auto;
    height: calc(100vh - 12rem);
    display: grid;
    place-items: center;
}

.create-post {

}

.register h2, .login h2, .create-post h2 {
    margin-bottom: 2rem;
}

.register small, .login small {
    display: block;
    margin-top: 1.5rem;
}

.register small a, .login small a {
    color: var(--color-primary);
}

/* authors */
.authors__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.authorPosts {
    align-items: center;
    color: rgb(222, 52, 52);
    margin-top: 0.5rem;
}

.author {
    background: var(--color-white);
    padding: 1rem;
    border-radius: var(--radius-3);
    display: flex;
    gap: 1rem;
    transition: var(--transition);
}

.author:hover {
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.1);
}

.author__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 0.3rem solid var(--color-bg);
    overflow: hidden;
}

.author__info p {
    margin-top: 1rem;
}

/* profile */

.profile__container {
    display: grid;
    place-items: center;
    text-align: center;
}

.profile__details {
    width: 100%;
}

.avatar__wrapper {
    width: 12rem;
    aspect-ratio: 1/1;
    position: relative;
    margin-inline: auto;
    margin-top: 1rem;
}

.profile__avatar {
    height: 100%;
    border-radius: 50%;
    border: 1rem solid var(--color-white);
    overflow: hidden;
}

.avatar__form {
    height: 1rem;
}

.avatar__form input {
    visibility: hidden;
}

.avatar__form label, .profile__avatar-btn {
    background: var(--color-gray-900);
    color: var(--color-white);
    font-size: 1.3rem;
    position: absolute;
    right: 2rem;
    bottom: 1.4rem;
    width: 3rem;
    height: 3rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
}

.profile__avatar-btn {
    cursor: pointer;
    padding: 0;
    background: var(--color-primary);
}

.profile__details h1 {
    margin-top: 1.5rem;
}

.form.profile__form {
    margin-top: 3rem;
    margin-inline: auto;
    width: var(--form-width);
}

/* dashbaord */
.dashboard__container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    min-height: 36vh;
}

.dashboard__post {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background: var(--color-white);
    padding: 0.5rem 1.2rem;
    border-radius: var(--radius-3);
}

.dashboard__post-info {
    display: flex;
    gap: 2rem;
    width: 100%;
    align-items: center;
}

.dashboard__post-thumbnail {
    width: 4rem;
    border-radius: var(--radius-1);
    overflow: hidden;
}

.dashboard__post-actions {
    display: flex;
    gap: 1rem;
}

/* custom style for react quill */
.ql-editor {
    overflow: hidden;
    background: var(--color-white);
    /* height: 50rem !important; */
}






/* ---------------loader-------------- */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
    background: #ffffff;
}

.loader__image {
    width: 4.5rem;
    aspect-ratio: 1/1;
}

/* new css */
.map {
    /* margin: 3%; */
    margin-top: 5%;
    border: 1px solid #c7cecd;
}

.bottom-img {
    width: 100%;
    height: 200px;
}

.wrapper {
    padding: 2rem 3rem;
  }
  
  .label {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .editor {
    margin-top: 1rem;
    height: 500px;
  }

  /* ------------------- quill editor ---------------------- */
  .container-quill {
    display: flex; /* Use flexbox */
    justify-content: space-evenly; /* Distribute items evenly with space between them */
  }
  
  .box {
    width: 40%; /* Adjust width as needed */
    padding: 2px;
    /* border: 1px solid #ccc; */
    box-sizing: border-box;
  }
  
.ql-toolbar {
  background-color: #50b69b; /* Change the background color */
}

button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: rgb(255, 255, 255) !important;
}

.ql-active .ql-stroke {
  fill: none;
  stroke: rgb(255, 255, 255) !important;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: rgb(255, 255, 255) !important;
  stroke: none;
}

.ql-active .ql-fill {
  fill: rgb(255, 255, 255) !important;
  stroke: none;
}

/* for buttons in dashboard */
.container-create {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }
  
  .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
  
  .col {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
  }
  
  .btn-3d {
    padding: 20px 40px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
    font-size: 1.2rem;
  }
  
  .btn-3d:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4);
  }
  
  .btn-3d:active {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  }

  /* for contacts */

  /* .posts__container {
    grid-template-columns: 1fr;
    gap: 1rem;
} */

.contacts__container {
    grid-template-columns: 1fr;
    gap: 1rem;
}

.contacts__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
}

.contacts h6 {
    color: #f42d2d;
}

.contacts h5 {
    color: #3a3937;
}

.contacts h4 {
    color: #0f6686;
}

.row_contacts {
    display: flex;
    align-items: center;
}

.icon-phone {
    display: inline-block;
    padding: 10px 10px 5px 10px; /* Top, Right, Bottom, Left */
    font-size: 24px; /* Adjust the font size as needed */
    color: #1fa194; /* Initial color */
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  .icon-phone:hover {
    color: #d2b10e; /* Hover color */
    transform: scale(1.2); /* Scale up on hover */
    animation: pulse 0.5s infinite; /* Apply a pulse animation */
  }

  .icon-container-contact {
    display: inline-block;
    padding-left: 10px; /* Top, Right, Bottom, Left */
    font-size: 16px; /* Adjust the font size as needed */
    color: #d42727; /* Initial color */
    transition: all 0.3s ease; /* Smooth transition */
  }

  .icon-container-contact:hover {
    color: #d2b10e; /* Hover color */
    transform: scale(1.2); /* Scale up on hover */
    animation: pulse 0.5s infinite; /* Apply a pulse animation */
  }

  .container-contact {
    display: flex;
    justify-content: space-between;
}

.left-section {
    display: flex;
    align-items: center;
}

.right-section {
    display: flex;
    flex-direction: column;
}

.row_contacts {
    display: flex;
    align-items: center;
}

.icon-container {
    margin-right: 10px; /* Adjust as needed */
}

.contactFilter {
    padding: 10px 10px 10px 20px;
    font-size: 16px;
    width: 200px;
    background-color: hsl(166, 83%, 37%);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* margin: 1%;  */
  }
.contactFilter:hover {
    background-color: #d2b10e !important;
  }  

/* for business */
.fixed-top {
    position: relative;
    top: 0.5;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1rem;
    background-color: rgba(255, 255, 255, 0); 
    text-align: center; /* Center the contents horizontally */
  }
  
.business-address {
    color: #1fa194 !important;
}

/* .business-category {
    color: #cd5230 !important;
    margin-top: -8%;
    text-align: right;
    margin-right: 2%;
} */

.post {
    position: relative;
  }
  
  .business-category-ribbon {
    position: absolute;
    top: 5;
    left: 0;
    background-color: rgba(255, 242, 4, 0.986); /* Red color for the ribbon */
    color: rgb(6, 6, 6); /* White text color */
    padding: 4px 10px; /* Padding for the ribbon */
    transform: rotate(-30deg); /* Rotate the ribbon */
    transform-origin: top left; /* Set the rotation origin */
    z-index: 1; /* Ensure the ribbon appears above the image */
    font-size: x-small;
    /* font-weight: bold; */
  }
  
  
.post_deatail-heading {
    color: #1d8b80;
}

.post-detail_author {
    padding: 4%;
}
  
/* for business */
.icon-business {
    display: inline-block;
    padding: 10px 10px 5px 10px; /* Top, Right, Bottom, Left */
    font-size: 16px; /* Adjust the font size as needed */
    color: #1fa194; /* Initial color */
    transition: all 0.3s ease; /* Smooth transition */
  }

  .ribbon-text-business {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 242, 4, 0.986);
    color: rgb(6, 6, 6);
    padding: 4px 10px;
    transform: rotate(-20deg);
    z-index: 1;
    font-size: small;
  }
  
  .overlay-text-business {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  
  .overlay-text-business > div {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.43);
    color: white;
    padding: 5px 10px;
    margin-right: 10px;
  }
  
.businessMap {
    padding: 2%;
}  

.container-notes {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1%;
}

.notes_images {
    margin: 2%;
    padding-bottom: 2%;
}

/* for complaimts */
.complaint-textarea {
    width: 100%; /* Adjust width as needed */
    padding: 8px; /* Adjust padding as needed */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    resize: vertical; /* Allow vertical resizing */
    overflow-y: auto; /* Add vertical scrollbar when needed */
  }


  /* MEDIA QUERY  */

  /* Media Queries (small devices) */

@media screen and (max-width: 800px) {
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    h3 {
        font-size: 1.35rem;
    }

    .container {
        width: var(--container-width-md);
    }

    /* navabr  */
    .nav__container {
        position: relative;
    }

    .nav__menu {
        position: absolute;
        /* top: 5rem; */
        right: 0;
        flex-direction: rpw;
        /* gap: 0; */
    }
/* 
    .nav__menu li {
        width: 100%;
        box-shadow: -2rem 4rem 4rem rgba(0, 0, 0, 0.26);
        animation: dropDown 500ms ease forwards;
        opacity: 0;
        transform: rotateX(90deg);
        transform-origin: top;
    } */

    /* animation for dropdown navmenu*/
    
    @keyframes dropDown {
        to {
            opacity: 1;
            transform: rotateX(0);
        }
    }

    .nav__menu li:nth-child(2) {
        animation-delay: 300ms;
    }

    .nav__menu li:nth-child(3) {
        animation-delay: 600ms;
    }

    .nav__menu li:nth-child(4) {
        animation-delay: 900ms;
    }
/* 
    .nav__menu li a {
        display: block;
        background: var(--color-white);
        padding: 0.85rem 2.5rem;
    } */

    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.5rem;
        cursor: pointer;
    }

 

    .footer__categories {
        flex-direction: column;
    }

    .post-detail {
        width: 100%;
        background: transparent;
        padding: 0;
    }
    
    .post-detail h1 {
        margin-top: 1rem;
    }

    .register, .login, .create-post {
        width: 100% ;
    }

    .form.profile__form {
        margin-top: 2.5rem;
        width: 100%;
    }

    .authors__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }

    .dashboard__post {
        flex-direction: column;
    }

    .dashboard__post-info {
        gap: 1.5rem;
    }

    .dashboard__post-thumbnail {
        width: 5rem;
    }

    .posts__container {
        /* display: grid; */
        grid-template-columns: repeat(1, 1fr);
        /* gap: 2rem; */
    }

    .map {
        /* margin: 3%; */
        margin-top: 4rem;
        width: 100%;
        height: auto;
    }

    .imageClassLogo{
        width: 60%;
    }

    .contactFilter {
        font-size: 14px; /* Adjust font size as needed */
        width: 11rem; /* Adjust width as needed */
        height: auto;
        display: inline-block;
        background-color: hsl(166, 83%, 37%) !important;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        /* margin: 1%; 
        margin-left: 5rem;
        margin-top: 0.5rem; */

    }

    .overlay-text-business {
        left: 5px;
        font-size: 0.7rem;
      }
      
      .overlay-text-business > div {
        color: white;
        padding: 0px 10px;
      }
}

/* .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2rem;
  } */

/* for header   */
/* for flag buttons */
.flagButton {
    cursor: pointer;
    padding: 0; /* Remove padding */
    margin-right: 0.1rem;
    border: none; /* Remove border */
    background-color: transparent;
    transition: transform 0.2s; /* Add transition for smooth scaling */
    margin-top: 4px;
  }
  
  .flagButton:hover {
    transform: scale(1.1); /* Enlarge button on hover */
  }

  .flagnp {
    margin-right: 1rem;
  }
  
  
  .contact_desc {
    color: #545151 !important; /* Change this color to whatever you need */
    font-size: 12px; /* Adjust the font size if needed */
    font-weight: normal; /* Adjust the font weight if needed */
  }

  /* contacts and seller  */
  .seller_heading {
    margin-top: 4rem;
  }

  .seller_table {
    margin-top: 2rem;
  }

  .table_heading {
    color: #ffffff !important;
    font-weight: 800 !important;
  }

  .table_row {
    background-color: #0a9172;
  }

  .icon-container-product {
    display: inline-block;
    padding-right: 10px; /* Top, Right, Bottom, Left */
    font-size: 16px; /* Adjust the font size as needed */
    color: #0a9172; /* Initial color */
    transition: all 0.3s ease; /* Smooth transition */
  }

  .icon-container-product:hover {
    color: #d2b10e; /* Hover color */
    transform: scale(1.2); /* Scale up on hover */
    animation: pulse 0.5s infinite; /* Apply a pulse animation */
  }


  /* for nagrik vada-patra  */
  .nagrik_container {
    margin: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 3%;
}

.red-text {
    color: red !important;
}

@media (max-width: 768px) {
    .nagrik_container {
        margin-top: 14%;
    }
}