/* .profile-section {
  margin-bottom: 40px;
} */

.graph-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.raw-container .graph-item {
  flex: 1 1 21%; /* Four items per row */
  min-width: 150px;
  max-width: 21%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pie-container .graph-item {
  flex: 1 1 45%; /* Two items per row */
  min-width: 45%;
  max-width: 45%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-container .graph-item {
  flex: 1 1 100%; /* One item per row */
  min-width: 300px;
  max-width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .raw-container .graph-item,
  .pie-container .graph-item,
  .chart-container .graph-item {
    /* flex: 1 1 100%;  */
    max-width: 100%;
  }
}

.user-view {
  margin-top: 4rem;
}

.sticky-tabs {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 61px; /* Adjust this value to match the height of your header */
  z-index: 1000; /* Ensure it stays on top of other content */
  background-color: rgb(255, 255, 255); /* Match the background color */
}

/* for tabs */

/* UserView.css */
.sticky-tabs .MuiTab-root {
  transition: background-color 0.3s ease;
}

.sticky-tabs .MuiTab-root:hover {
  background-color: transparent; /* Ensure no background color on hover */
}

.sticky-tabs {
  transition: all 0.3s ease;
}